var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{staticClass:"mb-2"},[_c('v-row',{staticClass:"align-center",attrs:{"no-gutters":""}},[(_vm.currentImageMeta)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-simple-checkbox',_vm._g({staticClass:"mt-1",attrs:{"disabled":!_vm.currentImageMeta,"on-icon":"visibility","off-icon":"visibility","color":"primary","hide-details":""},model:{value:(_vm.isImageLayerVisible),callback:function ($$v) {_vm.isImageLayerVisible=$$v},expression:"isImageLayerVisible"}},on)),_c('span',[_vm._v(_vm._s(_vm.$t('Floorplan image')))])]}}],null,false,4049305461)},[_c('span',[_vm._v(_vm._s(_vm.isImageLayerVisible ? _vm.$t('Hide image layer') : _vm.$t('Show image layer')))])]):_vm._e(),(_vm.currentImageMeta && _vm.editorMode)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-simple-checkbox',_vm._g({staticClass:"mt-0 ml-3",attrs:{"color":"primary","disabled":!_vm.isImageLayerVisible,"on-icon":"mdi-pencil","off-icon":"mdi-pencil","hide-details":""},model:{value:(_vm.isLevelImageEdited),callback:function ($$v) {_vm.isLevelImageEdited=$$v},expression:"isLevelImageEdited"}},on))]}}],null,false,3627299841)},[_c('span',[_vm._v(_vm._s(_vm.isLevelImageEdited ? _vm.$t('Moving off') : _vm.$t('Moving on')))])]):_vm._e(),(_vm.currentImageMeta && _vm.editorMode)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-simple-checkbox',_vm._g({staticClass:"mt-0 ml-1",attrs:{"color":"primary","disabled":!_vm.isImageLayerVisible || !_vm.isLevelImageEdited,"on-icon":"mdi-ruler","off-icon":"mdi-ruler","hide-details":""},on:{"input":function (enable) { return (enable ? _vm.enableScaling() : _vm.disableScaling()); }},model:{value:(_vm.isScaling),callback:function ($$v) {_vm.isScaling=$$v},expression:"isScaling"}},on))]}}],null,false,2523955371)},[_c('span',[_vm._v(_vm._s(_vm.$t('Specify the scale')))])]):_vm._e(),(_vm.currentImageMeta && _vm.editorMode)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-simple-checkbox',_vm._g({staticClass:"mt-0 ml-1",attrs:{"color":"primary","disabled":!_vm.isImageLayerVisible || !_vm.isLevelImageEdited,"on-icon":"mdi-map-marker-plus","off-icon":"mdi-map-marker-plus","hide-details":""},on:{"input":function (enable) { return (enable ? _vm.enableReferencing() : _vm.disableReferencing()); }},model:{value:(_vm.isReferencing),callback:function ($$v) {_vm.isReferencing=$$v},expression:"isReferencing"}},on))]}}],null,false,3061836792)},[_c('span',[_vm._v(_vm._s(_vm.$t('Georeference image')))])]):_vm._e(),(_vm.currentImageMeta && _vm.editorMode)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.deleteImage(_vm.editedLevel.id)}}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,false,754123948)},[_c('span',[_vm._v(_vm._s(_vm.$t('Delete')))])]):_vm._e()],1),(_vm.isScaling)?_c('v-row',{staticClass:"mb-6",attrs:{"no-gutters":""}},[_c('div',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.$t('Select two points on the image and enter their distance.'))+" ")]),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"type":"number","min":"0","max":"100","step":"0.1","suffix":"m","rules":[_vm.rules.required, _vm.rules.positiveNumber],"label":_vm.$t('Distance'),"hide-details":"auto"},model:{value:(_vm.scalingDistance),callback:function ($$v) {_vm.scalingDistance=_vm._n($$v)},expression:"scalingDistance"}})],1),_c('v-col',{staticClass:"d-flex justify-end align-center mt-4",attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"small":""},on:{"click":_vm.disableScaling}},[_vm._v(_vm._s(_vm.$t('Cancel')))]),_c('v-btn',{staticClass:"ml-2",attrs:{"disabled":_vm.scalingMarkers.length !== 2 || _vm.scalingDistance <= 0,"small":""},on:{"click":_vm.setScaling}},[_vm._v(_vm._s(_vm.$t('Ok')))])],1)],1):_vm._e(),(_vm.isReferencing)?_c('v-row',{staticClass:"mb-1",attrs:{"no-gutters":""}},[_c('div',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.$t('Select reference points on the image and enter their coordinates.'))+" ")]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn-toggle',{staticClass:"mt-1",attrs:{"mandatory":""},model:{value:(_vm.projection),callback:function ($$v) {_vm.projection=$$v},expression:"projection"}},[_c('v-btn',{attrs:{"text":"","width":"80","height":"32"}},[_vm._v("WGS84")]),_c('v-btn',{attrs:{"text":"","width":"80","height":"32"}},[_vm._v("EOV")])],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-6",attrs:{"fab":"","small":"","fixed":"","right":"","elevation":"3","disabled":_vm.referenceMarkers.length < 2 || !_vm.allCoordsSet},on:{"click":_vm.georeference}},on),[_c('v-icon',[_vm._v("mdi-map-marker-check-outline")])],1)]}}],null,false,3087718274)},[_c('span',[_vm._v(_vm._s(_vm.$t('Georeference')))])]),(_vm.referenceMarkers.length > 0)?_c('v-list',{staticClass:"pt-2 pb-0"},_vm._l((_vm.referenceMarkers),function(marker,index){return _c('v-list-item',{key:index,staticClass:"pl-0"},[_c('v-icon',{staticClass:"ml-0 mr-2 mt-1",attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(("mdi-numeric-" + (index + 1) + "-circle-outline"))+" ")]),_c('v-text-field',{staticClass:"pa-1",attrs:{"type":"number","label":_vm.$t('Longitude'),"hide-details":"auto"},on:{"change":function($event){return _vm.setCoord('lng', $event, index)}}}),_c('v-text-field',{staticClass:"pa-1",attrs:{"type":"number","label":_vm.$t('Latitude'),"hide-details":"auto"},on:{"change":function($event){return _vm.setCoord('lat', $event, index)}}})],1)}),1):_vm._e()],1)],1):_vm._e(),(_vm.currentImageMeta && _vm.editorMode)?_c('v-row',[_c('v-slider',{staticClass:"mt-0 mx-3",attrs:{"label":_vm.$t('Transparency'),"min":"0","max":"100","thumb-label":"","step":"10"},model:{value:(_vm.transparency),callback:function ($$v) {_vm.transparency=$$v},expression:"transparency"}})],1):_vm._e(),(!_vm.currentImageMeta && _vm.editorMode)?_c('v-row',{staticClass:"mt-2",attrs:{"no-gutters":""}},[_c('v-file-input',{staticClass:"mt-2 file-input",attrs:{"label":_vm.$t('Select image (max. 16 MB)'),"prepend-icon":"","clearable":"","dense":"","accept":"image/png, image/jpeg, image/tiff","show-size":"","no-details":"","rules":[_vm.rules.imageSize]},model:{value:(_vm.levelImageFile),callback:function ($$v) {_vm.levelImageFile=$$v},expression:"levelImageFile"}}),_c('v-tooltip',{attrs:{"bottom":"","open-delay":700},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mt-1 ml-2 mr-1",attrs:{"fab":"","x-small":"","color":"primary","disabled":!_vm.levelImageFile || !_vm.imageOk},on:{"click":_vm.uploadFloorplanImage}},on),[_c('v-icon',[_vm._v("mdi-upload")])],1)]}}],null,false,1320318097)},[_c('span',[_vm._v(_vm._s(_vm.$t('Upload image')))])])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }