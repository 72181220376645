<template>
  <v-form class="mb-2">
    <v-row no-gutters class="align-center">
      <v-tooltip bottom v-if="currentImageMeta">
        <template v-slot:activator="{ on }">
          <v-simple-checkbox
            v-on="on"
            class="mt-1"
            v-model="isImageLayerVisible"
            :disabled="!currentImageMeta"
            on-icon="visibility"
            off-icon="visibility"
            color="primary"
            hide-details
          ></v-simple-checkbox>
          <span>{{ $t('Floorplan image') }}</span>
        </template>
        <span>{{ isImageLayerVisible ? $t('Hide image layer') : $t('Show image layer') }}</span>
      </v-tooltip>
      <v-tooltip bottom v-if="currentImageMeta && editorMode">
        <template v-slot:activator="{ on }">
          <v-simple-checkbox
            class="mt-0 ml-3"
            v-on="on"
            color="primary"
            v-model="isLevelImageEdited"
            :disabled="!isImageLayerVisible"
            on-icon="mdi-pencil"
            off-icon="mdi-pencil"
            hide-details
          ></v-simple-checkbox>
        </template>
        <span>{{ isLevelImageEdited ? $t('Moving off') : $t('Moving on') }}</span>
      </v-tooltip>
      <v-tooltip bottom v-if="currentImageMeta && editorMode">
        <template v-slot:activator="{ on }">
          <v-simple-checkbox
            class="mt-0 ml-1"
            v-on="on"
            color="primary"
            v-model="isScaling"
            @input="(enable) => (enable ? enableScaling() : disableScaling())"
            :disabled="!isImageLayerVisible || !isLevelImageEdited"
            on-icon="mdi-ruler"
            off-icon="mdi-ruler"
            hide-details
          ></v-simple-checkbox>
        </template>
        <span>{{ $t('Specify the scale') }}</span>
      </v-tooltip>
      <v-tooltip bottom v-if="currentImageMeta && editorMode">
        <template v-slot:activator="{ on }">
          <v-simple-checkbox
            class="mt-0 ml-1"
            v-on="on"
            color="primary"
            v-model="isReferencing"
            @input="(enable) => (enable ? enableReferencing() : disableReferencing())"
            :disabled="!isImageLayerVisible || !isLevelImageEdited"
            on-icon="mdi-map-marker-plus"
            off-icon="mdi-map-marker-plus"
            hide-details
          ></v-simple-checkbox>
        </template>
        <span>{{ $t('Georeference image') }}</span>
      </v-tooltip>
      <v-tooltip bottom v-if="currentImageMeta && editorMode">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon color="primary" @click="deleteImage(editedLevel.id)"
            ><v-icon>mdi-delete</v-icon></v-btn
          >
        </template>
        <span>{{ $t('Delete') }}</span>
      </v-tooltip>
    </v-row>

    <v-row no-gutters class="mb-6" v-if="isScaling">
      <div class="caption">
        {{ $t('Select two points on the image and enter their distance.') }}
      </div>
      <v-col cols="6">
        <v-text-field
          v-model.number="scalingDistance"
          type="number"
          min="0"
          max="100"
          step="0.1"
          suffix="m"
          :rules="[rules.required, rules.positiveNumber]"
          :label="$t('Distance')"
          hide-details="auto"
        >
        </v-text-field>
      </v-col>
      <v-col cols="6" class="d-flex justify-end align-center mt-4">
        <v-btn small @click="disableScaling">{{ $t('Cancel') }}</v-btn>
        <v-btn
          :disabled="scalingMarkers.length !== 2 || scalingDistance <= 0"
          class="ml-2"
          small
          @click="setScaling"
          >{{ $t('Ok') }}</v-btn
        >
      </v-col>
    </v-row>
    <v-row no-gutters class="mb-1" v-if="isReferencing">
      <div class="caption">
        {{ $t('Select reference points on the image and enter their coordinates.') }}
      </div>
      <v-col cols="12">
        <v-btn-toggle v-model="projection" class="mt-1" mandatory>
          <v-btn text width="80" height="32">WGS84</v-btn>
          <v-btn text width="80" height="32">EOV</v-btn>
        </v-btn-toggle>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              fab
              small
              fixed
              right
              class="mr-6"
              elevation="3"
              v-on="on"
              @click="georeference"
              :disabled="referenceMarkers.length < 2 || !allCoordsSet"
              ><v-icon>mdi-map-marker-check-outline</v-icon></v-btn
            >
          </template>
          <span>{{ $t('Georeference') }}</span>
        </v-tooltip>
        <v-list v-if="referenceMarkers.length > 0" class="pt-2 pb-0">
          <v-list-item class="pl-0" v-for="(marker, index) in referenceMarkers" :key="index">
            <v-icon color="primary" class="ml-0 mr-2 mt-1">
              {{ `mdi-numeric-${index + 1}-circle-outline` }}
            </v-icon>
            <v-text-field
              class="pa-1"
              type="number"
              @change="setCoord('lng', $event, index)"
              :label="$t('Longitude')"
              hide-details="auto"
            ></v-text-field>
            <v-text-field
              class="pa-1"
              type="number"
              @change="setCoord('lat', $event, index)"
              :label="$t('Latitude')"
              hide-details="auto"
            ></v-text-field>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
    <v-row v-if="currentImageMeta && editorMode">
      <v-slider
        v-model="transparency"
        class="mt-0 mx-3"
        :label="$t('Transparency')"
        min="0"
        max="100"
        thumb-label
        step="10"
      ></v-slider>
    </v-row>
    <v-row v-if="!currentImageMeta && editorMode" no-gutters class="mt-2">
      <v-file-input
        class="mt-2 file-input"
        :label="$t('Select image (max. 16 MB)')"
        prepend-icon=""
        clearable
        dense
        v-model="levelImageFile"
        accept="image/png, image/jpeg, image/tiff"
        show-size
        no-details
        :rules="[rules.imageSize]"
      >
      </v-file-input>
      <v-tooltip bottom :open-delay="700">
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            fab
            x-small
            color="primary"
            class="mt-1 ml-2 mr-1"
            @click="uploadFloorplanImage"
            :disabled="!levelImageFile || !imageOk"
            ><v-icon>mdi-upload</v-icon></v-btn
          ></template
        ><span>{{ $t('Upload image') }}</span>
      </v-tooltip>
    </v-row>
  </v-form>
</template>

<style lang="scss" scoped></style>
<script>
import mapboxgl from 'mapbox-gl';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import {
  distance as turfDistance,
  booleanPointInPolygon as turfBooleanPointInPolygon,
  polygon as turfPolygon,
  point as turfPoint,
  bearing as turfBearing,
  bearingToAzimuth as turfBearingToAzimuth
} from '@turf/turf';
import ImageHandler from '../helpers/map/ImageHandler';
import constants from '~/shared/constants';
import rules from '../mixins/rules';
import i18n from '@/i18n';
import proj4 from 'proj4';

export default {
  name: 'ImagePanel',
  props: ['map'],

  data() {
    return {
      levelImageFile: undefined,
      levelImageHandler: new ImageHandler(),
      isLevelImageEdited: false,
      imageMarkerTL: undefined,
      imageMarkerBR: undefined,
      imageMarkerTop: undefined,
      imageMarkerRot: undefined,
      isScaling: false,
      isReferencing: false,
      scalingDistance: 0,
      scalingMarkers: [],
      referenceMarkers: [],
      referenceCoords: [],
      projection: 0 // 0: WGS84, 1: EOV
    };
  },

  computed: {
    ...mapGetters('image', ['currentImageMeta', 'isLayerVisible', 'imageTransparency']),
    ...mapGetters('level', ['editedLevel']),
    ...mapGetters('status', ['editorMode', 'currentDrawMode']),
    isImageLayerVisible: {
      get() {
        return this.isLayerVisible;
      },
      set(value) {
        this.setLayerVisibility(value);
      }
    },
    transparency: {
      get() {
        return this.imageTransparency;
      },
      set(value) {
        this.setLayerTransparency(value);
      }
    },
    imageOk() {
      if (this.levelImageFile && this.levelImageFile.size < constants.IMAGE_SIZE_LIMIT) {
        return true;
      } else {
        return false;
      }
    },
    allCoordsSet() {
      if (this.referenceMarkers.length !== this.referenceCoords.length) {
        return false;
      } else {
        for (const coord of this.referenceCoords) {
          if (!coord.lat || !coord.lng) {
            return false;
          }
        }
        return true;
      }
    }
  },

  watch: {
    currentDrawMode() {
      this.isLevelImageEdited = false;
    },
    editorMode() {
      if (!this.editorMode && this.isLevelImageEdited) {
        this.isLevelImageEdited = false;
      }

      if (!this.editorMode) {
        this.disableScaling();
        this.hideFloorplanImageMarkers();
      }
    },
    editedLevel: {
      async handler() {
        this.isLevelImageEdited = false;
      },
      immediate: true
    },
    isLevelImageEdited() {
      if (this.isLevelImageEdited) {
        this.showFloorplanImageMarkers();
      } else {
        this.hideFloorplanImageMarkers();
        this.disableReferencing();
        this.hideFloorplanImageMarkers();
        this.disableScaling();
        this.hideFloorplanImageMarkers();
      }
    },
    isImageLayerVisible() {
      if (this.map.getLayer('level-image')) {
        this.map.setLayoutProperty(
          'level-image',
          'visibility',
          this.isImageLayerVisible ? 'visible' : 'none'
        );
      }

      if (!this.isImageLayerVisible) {
        this.hideFloorplanImageMarkers();
        this.disableScaling();
        this.hideFloorplanImageMarkers();
        this.disableReferencing();
        this.hideFloorplanImageMarkers();
      } else if (this.isLevelImageEdited) {
        this.showFloorplanImageMarkers();
      }
    },
    currentImageMeta() {
      this.hideFloorplanImageMarkers();
      if (this.isLevelImageEdited && !this.isReferencing) {
        this.showFloorplanImageMarkers();
      }
    },
    transparency() {
      if (this.map.getLayer('level-image')) {
        this.map.setPaintProperty('level-image', 'raster-opacity', 1 - this.transparency / 100);
      }
    }
  },

  methods: {
    ...mapMutations('image', {
      setLayerVisibility: 'setLayerVisibility',
      setLayerTransparency: 'setLayerTransparency',
      setImageConvertInProgress: 'setImageConvertInProgress'
    }),
    ...mapActions('image', ['uploadImage', 'deleteImage', 'setImageMeta']),
    ...mapActions('alert', ['error']),

    getDistanceOnMap(onlyScaling = true) {
      if (onlyScaling) {
        if (this.scalingMarkers.length === 2) {
          const [marker1, marker2] = this.scalingMarkers;
          return turfDistance(marker1.getLngLat().toArray(), marker2.getLngLat().toArray()) * 1000;
        }
      } else {
        if (this.referenceMarkers.length === 2) {
          const [marker1, marker2] = this.referenceMarkers;
          return turfDistance(marker1.getLngLat().toArray(), marker2.getLngLat().toArray()) * 1000;
        }
      }
    },

    clickHandler(e) {
      if (this.isScaling) {
        if (this.scalingMarkers.length < 2) {
          let marker = new mapboxgl.Marker({ draggable: true }).setLngLat(e.lngLat).addTo(this.map);
          marker.on('dragend', this.getDistanceOnMap);

          this.scalingMarkers.push(marker);

          this.getDistanceOnMap();
        }
      } else if (this.isReferencing) {
        if (this.referenceMarkers.length < constants.MAX_IMAGE_REFERENCE_POINTS) {
          let marker = new mapboxgl.Marker({ draggable: true }).setLngLat(e.lngLat).addTo(this.map);

          this.referenceCoords.push({
            index: this.referenceCoords.length,
            lat: undefined,
            lng: undefined
          });
          this.referenceMarkers.push(marker);
        }
      }
    },

    setCoord(coord, event, index) {
      this.referenceCoords.find((coord) => coord.index === index)[coord] = parseFloat(event);
    },

    georeference() {
      for (const marker of this.referenceMarkers) {
        const lngLat = marker.getLngLat();
        const point = turfPoint([lngLat.lng, lngLat.lat]);
        const polygon = turfPolygon([
          [...this.currentImageMeta.coordinates, this.currentImageMeta.coordinates[0]]
        ]);
        if (!turfBooleanPointInPolygon(point, polygon)) {
          this.error(i18n.t('Markers must be on the image!'));
          return;
        }
      }

      const coords = JSON.parse(JSON.stringify(this.referenceCoords));

      // convert coords to WGS84 if given in EOV
      if (this.projection === 1) {
        for (let point of coords) {
          const newCoords = proj4(constants.EOV_PROJECTION).inverse([point.lng, point.lat]);
          point.lng = newCoords[0];
          point.lat = newCoords[1];
        }
      }

      const markerPositions = [];
      for (const marker of this.referenceMarkers) {
        const lngLat = marker.getLngLat();
        markerPositions.push([lngLat.lng, lngLat.lat]);
      }
      this.levelImageHandler.initGeoref(markerPositions);

      // scaling
      const distanceOnMap = this.getDistanceOnMap(false);
      const distanceOfCoords =
        turfDistance([coords[0].lng, coords[0].lat], [coords[1].lng, coords[1].lat]) * 1000;

      const proportion = distanceOfCoords / distanceOnMap;

      this.levelImageHandler.initCornerMove(
        this.currentImageMeta.coordinates,
        this.currentImageMeta.coordinates[0],
        this.currentImageMeta.coordinates[2]
      );

      const scaleCoords = this.levelImageHandler.calcScalingCoords(proportion);
      let data = {
        ...this.currentImageMeta,
        coordinates: scaleCoords,
        skipHistory: true
      };
      this.setImageMeta(data);

      // rotation
      const [marker1, marker2] = this.referenceMarkers;
      const currentBearing = turfBearingToAzimuth(
        turfBearing(marker1.getLngLat().toArray(), marker2.getLngLat().toArray())
      );

      const desiredBearing = turfBearingToAzimuth(
        turfBearing([coords[0].lng, coords[0].lat], [coords[1].lng, coords[1].lat])
      );

      const bearingDiff = (desiredBearing - currentBearing + 360) % 360;

      const rotateCoords = this.levelImageHandler.rotateByAngle(
        this.currentImageMeta.coordinates,
        bearingDiff
      );
      data = {
        ...this.currentImageMeta,
        coordinates: rotateCoords,
        skipHistory: true
      };
      this.setImageMeta(data);

      // translation
      const translateCoords = this.levelImageHandler.translate(this.currentImageMeta.coordinates, [
        coords[0].lng,
        coords[0].lat
      ]);
      data = {
        ...this.currentImageMeta,
        coordinates: translateCoords,
        skipHistory: false
      };
      this.setImageMeta(data);
      this.disableReferencing();
    },

    enableScaling() {
      if (this.isReferencing) {
        this.disableReferencing();
      }
      this.hideFloorplanImageMarkers();
      this.isScaling = true;
      this.map.on('click', this.clickHandler);
      this.scalingMarkers = [];
      this.map.getCanvas().style.cursor = 'crosshair';
    },

    disableScaling() {
      this.isScaling = false;

      if (this.editorMode) {
        this.showFloorplanImageMarkers();
      }

      this.scalingMarkers.forEach((marker) => {
        marker.remove();
      });
      this.scalingMarkers = [];
      this.scalingDistance = 0;
      this.map.off('click', this.clickHandler);
      this.map.getCanvas().style.cursor = 'default';
    },

    enableReferencing() {
      if (this.isScaling) {
        this.disableScaling();
      }
      this.hideFloorplanImageMarkers();
      this.isReferencing = true;
      this.map.on('click', this.clickHandler);
      this.referenceMarkers = [];
      this.map.getCanvas().style.cursor = 'crosshair';
    },

    disableReferencing() {
      this.isReferencing = false;

      if (this.editorMode) {
        this.showFloorplanImageMarkers();
      }

      this.referenceMarkers.forEach((marker) => {
        marker.remove();
      });
      this.referenceMarkers = [];
      this.referenceCoords = [];
      this.map.off('click', this.clickHandler);
      this.map.getCanvas().style.cursor = 'default';
    },

    setScaling() {
      const distanceOnMap = this.getDistanceOnMap();
      const proportion = this.scalingDistance / distanceOnMap;

      this.levelImageHandler.initCornerMove(
        this.currentImageMeta.coordinates,
        this.currentImageMeta.coordinates[0],
        this.currentImageMeta.coordinates[2]
      );

      const coordinates = this.levelImageHandler.calcScalingCoords(proportion);
      this.setImageMarkerPositions(coordinates);

      const data = {
        ...this.currentImageMeta,
        coordinates
      };

      this.setImageMeta(data);

      this.disableScaling();
    },

    async uploadFloorplanImage() {
      this.setImageConvertInProgress(true);

      const bounds = this.map.getBounds();
      const tl = [bounds._sw.lng, bounds._ne.lat];
      const br = [bounds._ne.lng, bounds._sw.lat];

      const { coordinates, mimeType, image } = await this.levelImageHandler.getCornersAndMimeType({
        tl,
        br,
        file: this.levelImageFile
      });

      const meta = {
        levelId: this.editedLevel.id,
        size: this.levelImageFile.size,
        coordinates,
        mimeType
      };

      await this.uploadImage({ image, meta });

      this.levelImageFile = undefined;
      this.isLevelImageEdited = true;
      this.setImageConvertInProgress(false);
    },

    showFloorplanImageMarkers() {
      if (!this.currentImageMeta) {
        return;
      }
      //to change arrow colors, use changeSvgColor(svgString, hexCode) from shared/utils.js
      const el1 = document.createElement('div');
      el1.style.backgroundImage = constants.STYLES.IMAGE_MANIPULATION_ARROWS.RESIZE;
      el1.style.width = constants.STYLES.IMAGE_MANIPULATION_ARROWS.WIDTH;
      el1.style.height = constants.STYLES.IMAGE_MANIPULATION_ARROWS.HEIGHT;
      el1.style.backgroundSize = '100%';

      const el2 = document.createElement('div');
      el2.style.backgroundImage = constants.STYLES.IMAGE_MANIPULATION_ARROWS.RESIZE;
      el2.style.width = constants.STYLES.IMAGE_MANIPULATION_ARROWS.WIDTH;
      el2.style.height = constants.STYLES.IMAGE_MANIPULATION_ARROWS.HEIGHT;
      el2.style.backgroundSize = '100%';

      const el3 = document.createElement('div');
      el3.style.backgroundImage = constants.STYLES.IMAGE_MANIPULATION_ARROWS.MOVE;
      el3.style.width = constants.STYLES.IMAGE_MANIPULATION_ARROWS.WIDTH;
      el3.style.height = constants.STYLES.IMAGE_MANIPULATION_ARROWS.HEIGHT;
      el3.style.backgroundSize = '100%';

      const el4 = document.createElement('div');
      el4.style.backgroundImage = constants.STYLES.IMAGE_MANIPULATION_ARROWS.ROTATE;
      el4.style.width = constants.STYLES.IMAGE_MANIPULATION_ARROWS.WIDTH;
      el4.style.height = constants.STYLES.IMAGE_MANIPULATION_ARROWS.HEIGHT;
      el4.style.backgroundSize = '100%';

      this.imageMarkerTL = new mapboxgl.Marker({
        draggable: true,
        element: el1
      })
        .setLngLat(this.currentImageMeta.coordinates[0])
        .setRotation(this.levelImageHandler.getBearing(this.currentImageMeta.coordinates))
        .addTo(this.map);

      this.imageMarkerBR = new mapboxgl.Marker({
        draggable: true,
        element: el2
      })
        .setLngLat(this.currentImageMeta.coordinates[2])
        .setRotation(this.levelImageHandler.getBearing(this.currentImageMeta.coordinates))
        .addTo(this.map);

      this.imageMarkerTop = new mapboxgl.Marker({
        draggable: true,
        element: el3
      })
        .setLngLat(this.levelImageHandler.getTop(this.currentImageMeta.coordinates))
        .setRotation(this.levelImageHandler.getBearing(this.currentImageMeta.coordinates))
        .addTo(this.map);

      this.imageMarkerRot = new mapboxgl.Marker({
        draggable: true,
        element: el4
      })
        .setLngLat(this.currentImageMeta.coordinates[1])
        .setRotation(this.levelImageHandler.getBearing(this.currentImageMeta.coordinates))
        .addTo(this.map);

      let self = this;

      this.imageMarkerTL.on('dragstart', () => {
        self.levelImageHandler.initCornerMove(
          self.currentImageMeta.coordinates,
          self.currentImageMeta.coordinates[2],
          self.currentImageMeta.coordinates[0]
        );
        self.map.getCanvas().style.cursor = 'grabbing';
      });
      this.imageMarkerTL.on('drag', () => {
        const lngLat = self.imageMarkerTL.getLngLat();
        const coordinates = self.levelImageHandler.calcCornerMoveCoords([lngLat.lng, lngLat.lat]);
        let mySource = self.map.getSource('level-image');
        mySource.setCoordinates(coordinates);
        self.setImageMarkerPositions(coordinates);
      });
      this.imageMarkerTL.on('dragend', () => {
        const lngLat = self.imageMarkerTL.getLngLat();
        const coordinates = self.levelImageHandler.calcCornerMoveCoords([lngLat.lng, lngLat.lat]);
        const data = {
          ...this.currentImageMeta,
          coordinates
        };
        self.setImageMeta(data);
        self.map.getCanvas().style.cursor = 'default';
        self.setImageMarkerPositions(coordinates);
      });

      this.imageMarkerBR.on('dragstart', () => {
        self.levelImageHandler.initCornerMove(
          self.currentImageMeta.coordinates,
          self.currentImageMeta.coordinates[0],
          self.currentImageMeta.coordinates[2]
        );
        self.map.getCanvas().style.cursor = 'grabbing';
      });
      this.imageMarkerBR.on('drag', () => {
        const lngLat = self.imageMarkerBR.getLngLat();
        const coordinates = self.levelImageHandler.calcCornerMoveCoords([lngLat.lng, lngLat.lat]);
        let mySource = self.map.getSource('level-image');
        mySource.setCoordinates(coordinates);
        self.setImageMarkerPositions(coordinates);
      });
      this.imageMarkerBR.on('dragend', () => {
        const lngLat = self.imageMarkerBR.getLngLat();
        const coordinates = self.levelImageHandler.calcCornerMoveCoords([lngLat.lng, lngLat.lat]);
        const data = {
          ...this.currentImageMeta,
          coordinates
        };
        self.setImageMeta(data);
        self.map.getCanvas().style.cursor = 'default';
        self.setImageMarkerPositions(coordinates);
      });

      this.imageMarkerTop.on('dragstart', () => {
        self.levelImageHandler.initTopMove(self.currentImageMeta.coordinates);
        self.map.getCanvas().style.cursor = 'grabbing';
      });
      this.imageMarkerTop.on('drag', () => {
        const lngLat = self.imageMarkerTop.getLngLat();
        const coordinates = self.levelImageHandler.calcTopMoveCoords([lngLat.lng, lngLat.lat]);
        let mySource = self.map.getSource('level-image');
        mySource.setCoordinates(coordinates);
        self.setImageMarkerPositions(coordinates);
      });
      this.imageMarkerTop.on('dragend', () => {
        const lngLat = self.imageMarkerTop.getLngLat();
        const coordinates = self.levelImageHandler.calcTopMoveCoords([lngLat.lng, lngLat.lat]);
        const data = {
          ...this.currentImageMeta,
          coordinates
        };
        self.setImageMeta(data);
        self.map.getCanvas().style.cursor = 'default';
        self.setImageMarkerPositions(coordinates);
      });

      this.imageMarkerRot.on('dragstart', () => {
        self.levelImageHandler.initRotation(
          self.currentImageMeta.coordinates,
          self.levelImageHandler.getCenter(self.currentImageMeta.coordinates),
          self.currentImageMeta.coordinates[1]
        );
        self.map.getCanvas().style.cursor = 'grabbing';
      });
      this.imageMarkerRot.on('drag', () => {
        const lngLat = self.imageMarkerRot.getLngLat();
        const coordinates = self.levelImageHandler.calcRotationCoords([lngLat.lng, lngLat.lat]);
        let mySource = self.map.getSource('level-image');
        mySource.setCoordinates(coordinates);
        self.setImageMarkerPositions(coordinates);
      });
      this.imageMarkerRot.on('dragend', () => {
        const lngLat = self.imageMarkerRot.getLngLat();
        const coordinates = self.levelImageHandler.calcRotationCoords([lngLat.lng, lngLat.lat]);
        const data = {
          ...this.currentImageMeta,
          coordinates
        };
        self.setImageMeta(data);
        self.map.getCanvas().style.cursor = 'default';
        self.setImageMarkerPositions(coordinates);
      });
    },

    hideFloorplanImageMarkers() {
      if (this.imageMarkerTL) {
        this.imageMarkerTL.remove();
      }
      if (this.imageMarkerBR) {
        this.imageMarkerBR.remove();
      }
      if (this.imageMarkerTop) {
        this.imageMarkerTop.remove();
      }
      if (this.imageMarkerRot) {
        this.imageMarkerRot.remove();
      }

      this.imageMarkerTL = undefined;
      this.imageMarkerBR = undefined;
      this.imageMarkerTop = undefined;
      this.imageMarkerRot = undefined;
    },

    setImageMarkerPositions(coords) {
      if (this.imageMarkerTL) {
        this.imageMarkerTL.setLngLat(coords[0]);
        this.imageMarkerTL.setRotation(this.levelImageHandler.getBearing(coords));
      }
      if (this.imageMarkerBR) {
        this.imageMarkerBR.setLngLat(coords[2]);
        this.imageMarkerBR.setRotation(this.levelImageHandler.getBearing(coords));
      }
      if (this.imageMarkerTop && this.levelImageHandler) {
        this.imageMarkerTop.setLngLat(this.levelImageHandler.getTop(coords));
        this.imageMarkerTop.setRotation(this.levelImageHandler.getBearing(coords));
      }
      if (this.imageMarkerRot) {
        this.imageMarkerRot.setLngLat(coords[1]);
        this.imageMarkerRot.setRotation(this.levelImageHandler.getBearing(coords));
      }
    }
  },

  beforeDestroy() {
    this.hideFloorplanImageMarkers();
    this.disableReferencing();
    this.hideFloorplanImageMarkers();
    this.disableScaling();
    this.hideFloorplanImageMarkers();
  },

  components: {},
  mixins: [rules]
};
</script>
