<template>
  <div>
    <v-list dense>
      <v-subheader class="pa-0"
        >{{ $t('Levels') }}
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <gli-editor-button
              color="primary"
              fab
              x-small
              elevation="3"
              dark
              v-on="on"
              @click="addNew()"
            >
              <v-icon>add</v-icon>
            </gli-editor-button>
          </template>
          <span>{{ $t('Add new level') }}</span>
        </v-tooltip>
      </v-subheader>
      <draggable
        class="draggable pt-1 pr-0"
        v-model="levels"
        handle=".handle"
        @change="levelOrderChanged($event)"
      >
        <v-list-item class="pa-0" v-for="(level, index) in levels" :key="index">
          <v-list-item-icon class="ma-0 mt-2">
            <v-icon class="handle">mdi-drag</v-icon>
          </v-list-item-icon>
          <v-tooltip bottom :disabled="level.isStreetLevel">
            <template v-slot:activator="{ on, attrs }">
              <v-list-item-icon class="ma-0 mt-2" v-bind="attrs" v-on="on">
                <v-icon
                  small
                  :class="{
                    'info--text': level.isStreetLevel,
                    'grey--text': !level.isStreetLevel
                  }"
                  :click="!level.isStreetLevel"
                  @click="changeStreetLevel(level)"
                >
                  mdi-pin
                </v-icon>
              </v-list-item-icon>
            </template>
            <span>{{ $t('Set as street level') }}</span>
          </v-tooltip>
          <v-list-item-content class="px-1 py-0">
            <v-list-item-title>
              <gli-text-field
                class="pb-1 ma-0 font-weight-normal"
                dense
                v-model="level.name"
                :rules="[rules.required]"
                hide-details
                @change="() => addToHistory(constants.OPERATIONS.LEVEL.RENAME)"
              ></gli-text-field>
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon class="ma-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <span v-on="on">
                  <gli-editor-button
                    class="ml-2"
                    fab
                    x-small
                    elevation="3"
                    @click="editImageOnLevel(levels[index].id)"
                    :color="
                      currentDrawMode === constants.DRAW_MODES.IMAGE &&
                      editedLevel.order === levels.length - index - undergroundLevelsCount
                        ? 'primary'
                        : undefined
                    "
                  >
                    <v-icon>mdi-floor-plan</v-icon>
                  </gli-editor-button>
                </span>
              </template>
              <span>{{ $t('Image layer settings') }}</span>
            </v-tooltip>
          </v-list-item-icon>
          <v-list-item-icon class="ma-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <span v-on="on">
                  <gli-editor-button
                    class="ml-2"
                    fab
                    x-small
                    elevation="3"
                    @click="duplicateLevel(index)"
                  >
                    <v-icon>mdi-content-copy</v-icon>
                  </gli-editor-button>
                </span>
              </template>
              <span>{{ $t('Duplicate level') }}</span>
            </v-tooltip>
          </v-list-item-icon>
          <v-list-item-icon class="ma-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <span v-on="on">
                  <gli-editor-button
                    class="ml-2"
                    color="error"
                    fab
                    x-small
                    elevation="3"
                    @click="remove(index)"
                    :disabled="levels.length <= 1 || level.isStreetLevel"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </gli-editor-button>
                </span>
              </template>
              <span v-if="levels.length <= 1">{{ $t('Bottom level can not be deleted') }}</span>
              <span v-else-if="level.isStreetLevel">{{
                $t('Street level can not be deleted')
              }}</span>
              <span v-else>{{ $t('Delete level') }}</span>
            </v-tooltip>
          </v-list-item-icon>
        </v-list-item>
      </draggable>
    </v-list>
    <image-panel v-show="currentDrawMode === constants.DRAW_MODES.IMAGE" :map="map"> </image-panel>
  </div>
</template>

<style lang="scss" scoped>
.handle {
  cursor: move;
}
.level-name {
  width: 100%;
}
.draggable {
  overflow-y: auto;
  max-height: calc(100vh - 580px);
  overflow-x: hidden;
  scrollbar-width: thin;
  padding-right: 4px;
}

.draggable::-webkit-scrollbar {
  width: 6px;

  background-color: #f1f1f1;
}

.draggable::-webkit-scrollbar-thumb {
  background: var(--v-primary-base);
}
</style>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import rules from '../mixins/rules';
import draggable from 'vuedraggable';
import constants from '~/shared/constants';
import ImagePanel from '../components/ImagePanel.vue';

export default {
  name: 'LevelEditor',
  props: ['map', 'draw'],

  data() {
    return {
      constants,
      levels: []
    };
  },

  mounted() {
    this.levels = this.levelsFromStore;
  },

  computed: {
    ...mapGetters('site', ['isNew', 'editedSite']),
    ...mapGetters('level', ['editedLevel', 'numberOfNewLevels']),
    ...mapGetters('level', { levelsFromStore: 'levels' }),
    ...mapGetters('status', ['currentDrawMode']),
    ...mapFields('site', ['editedSite.name']),

    //includes streetlevel
    undergroundLevelsCount() {
      return this.levels.filter((level) => level.order < 1).length;
    }
  },

  methods: {
    ...mapActions('level', ['addNew', 'delete', 'order', 'change']),
    ...mapActions('history', { addToHistory: 'add' }),
    ...mapActions('image', ['changeEditImageOnSetup']),
    ...mapMutations('level', ['setStreetLevel']),

    remove(index) {
      this.delete(index);
    },

    duplicateLevel(index) {
      this.$emit('duplicateLevel', index);
    },

    editImageOnLevel(id) {
      if (this.currentDrawMode === constants.DRAW_MODES.IMAGE && this.editedLevel.id === id) {
        this.$emit('setupLayers');
      } else {
        this.changeEditImageOnSetup(true);
        if (this.editedLevel.id === id) {
          this.$emit('setupLayers');
        } else {
          this.change(id);
        }
      }
    },

    levelOrderChanged(event) {
      this.order({ levels: this.levels, movedLevelId: event.moved.element.id });
    },

    changeStreetLevel(level) {
      if (!level.isStreetLevel) {
        this.setStreetLevel(level.id);
        this.addToHistory(constants.OPERATIONS.LEVEL.STREET_LEVEL);
      }
    }
  },

  watch: {
    levelsFromStore() {
      this.levels = this.levelsFromStore;
    }
  },

  components: { draggable, ImagePanel },
  mixins: [rules]
};
</script>
