var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-list',{attrs:{"dense":""}},[_c('v-subheader',{staticClass:"pa-0"},[_vm._v(_vm._s(_vm.$t('Levels'))+" "),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('gli-editor-button',_vm._g({attrs:{"color":"primary","fab":"","x-small":"","elevation":"3","dark":""},on:{"click":function($event){return _vm.addNew()}}},on),[_c('v-icon',[_vm._v("add")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('Add new level')))])])],1),_c('draggable',{staticClass:"draggable pt-1 pr-0",attrs:{"handle":".handle"},on:{"change":function($event){return _vm.levelOrderChanged($event)}},model:{value:(_vm.levels),callback:function ($$v) {_vm.levels=$$v},expression:"levels"}},_vm._l((_vm.levels),function(level,index){return _c('v-list-item',{key:index,staticClass:"pa-0"},[_c('v-list-item-icon',{staticClass:"ma-0 mt-2"},[_c('v-icon',{staticClass:"handle"},[_vm._v("mdi-drag")])],1),_c('v-tooltip',{attrs:{"bottom":"","disabled":level.isStreetLevel},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item-icon',_vm._g(_vm._b({staticClass:"ma-0 mt-2"},'v-list-item-icon',attrs,false),on),[_c('v-icon',{class:{
                  'info--text': level.isStreetLevel,
                  'grey--text': !level.isStreetLevel
                },attrs:{"small":"","click":!level.isStreetLevel},on:{"click":function($event){return _vm.changeStreetLevel(level)}}},[_vm._v(" mdi-pin ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('Set as street level')))])]),_c('v-list-item-content',{staticClass:"px-1 py-0"},[_c('v-list-item-title',[_c('gli-text-field',{staticClass:"pb-1 ma-0 font-weight-normal",attrs:{"dense":"","rules":[_vm.rules.required],"hide-details":""},on:{"change":function () { return _vm.addToHistory(_vm.constants.OPERATIONS.LEVEL.RENAME); }},model:{value:(level.name),callback:function ($$v) {_vm.$set(level, "name", $$v)},expression:"level.name"}})],1)],1),_c('v-list-item-icon',{staticClass:"ma-0"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('span',_vm._g({},on),[_c('gli-editor-button',{staticClass:"ml-2",attrs:{"fab":"","x-small":"","elevation":"3","color":_vm.currentDrawMode === _vm.constants.DRAW_MODES.IMAGE &&
                    _vm.editedLevel.order === _vm.levels.length - index - _vm.undergroundLevelsCount
                      ? 'primary'
                      : undefined},on:{"click":function($event){return _vm.editImageOnLevel(_vm.levels[index].id)}}},[_c('v-icon',[_vm._v("mdi-floor-plan")])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('Image layer settings')))])])],1),_c('v-list-item-icon',{staticClass:"ma-0"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('span',_vm._g({},on),[_c('gli-editor-button',{staticClass:"ml-2",attrs:{"fab":"","x-small":"","elevation":"3"},on:{"click":function($event){return _vm.duplicateLevel(index)}}},[_c('v-icon',[_vm._v("mdi-content-copy")])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('Duplicate level')))])])],1),_c('v-list-item-icon',{staticClass:"ma-0"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('span',_vm._g({},on),[_c('gli-editor-button',{staticClass:"ml-2",attrs:{"color":"error","fab":"","x-small":"","elevation":"3","disabled":_vm.levels.length <= 1 || level.isStreetLevel},on:{"click":function($event){return _vm.remove(index)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)]}}],null,true)},[(_vm.levels.length <= 1)?_c('span',[_vm._v(_vm._s(_vm.$t('Bottom level can not be deleted')))]):(level.isStreetLevel)?_c('span',[_vm._v(_vm._s(_vm.$t('Street level can not be deleted')))]):_c('span',[_vm._v(_vm._s(_vm.$t('Delete level')))])])],1)],1)}),1)],1),_c('image-panel',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentDrawMode === _vm.constants.DRAW_MODES.IMAGE),expression:"currentDrawMode === constants.DRAW_MODES.IMAGE"}],attrs:{"map":_vm.map}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }